<template>
    <section id="contact" class="py-5">
        <the-banner-small>Contact</the-banner-small>
        <div class="container my-3">
            <div class="row">
                <div class="col-md-6">
                    <h3>Onze bereikbaarheid</h3>
                    <hr />
                    <p>Voor afspraken, hulp, advies en vrijblijvende informatie is het Sint-Martinusfonds 24/7 bereikbaar.</p>
                    <p><span class="bold">Verdeelpunt Berlare:</span>
                        <ul>
                            <li>Diaken Raoul Verhaeven: +32 (0) 475 96 89 82</li>
                            <li>Huguette D'hondt: +32 (0) 479 46 54 60</li>
                            <li>Machteld Osselaer: +32 (0) 477 20 29 19</li>
                            <li>Albertine De Vos: +32 (0) 474 89 81 97</li>
                            <li>Angèle Van Malderen: +32 (0) 472 25 27 87</li>
                            <li>Erna Broekaert: +32 (0) 478 34 09 05</li>
                        </ul>
                        Ook op elke 2e donderdag van elke maand tussen 11.00u en 17.00u zijn de medewerkers bereikbaar in het lokaal: Dorp 33 Berlare.
                    </p>
                    <p><span class="bold">Verdeelpunt Uitbergen:</span>
                        <ul>
                            <li>Rony De Vleeshouwer</li>
                        </ul>
                        Ook op elke 2° donderdag van de maand zijn de medewerkers bereikbaar in het lokaal: Oud Gemeentehuis, Uitbergen.
                    </p>
                    <p><span class="bold">Verdeelpunt Overmere:</span>
                        <ul>
                            <li>Laurette Jacobs: +32 (0) 485 28 64 95 </li>
                        </ul>
                        Ook op elke 2° donderdag van de maand zijn de medewerkers bereikbaar in het lokaal : lokaal Chiro, Kloosterlandstraat, Overmere.
                    </p>
                </div>
                <div class="col-md-6">
                    <p class="border border-success p-3" v-if="messageSent">Uw bericht werd succesvol verstuurd</p>
                    <p class="border border-danger p-3" v-if="messageError">Uw berricht kon niet verstuurd worden. Gelieve later opnieuw te proberen.</p>
                    <h3>Contact formulier</h3>
                    <hr />
                    <form @submit.prevent="createMessage">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-user"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Naam" v-model="name">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-envelope"></i>
                                </span>
                            </div>
                            <input type="email" class="form-control" placeholder="Email" v-model="email">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-pencil-alt"></i>
                                </span>
                            </div>
                            <textarea class="form-control" placeholder="Uw vraag of boodschap" rows="5" v-model="body"></textarea>
                        </div>
                        <button type="submit" class="btn btn-secondary btn-block">Verzenden</button>
                    </form>
                </div>
                <div class="col-12">
                    <p>
                        <i>Alle gesprekken dienaangaande gaan door bij de aanvragers thuis en verlopen met de vereiste discretie. De hulpzoekende mensen worden geholpen door één van de medewerk(st)ers of door Raoul Verhaeven.  Daar waar nodig, worden onmiddellijk de nodige stappen ondernomen.</i>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 py-3">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.3891245899554!2d3.9432505161146745!3d51.04590237956188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c39bf3eec82283%3A0x11b4bcf2874fb2d4!2sBaron%20Tibbautstraat%208%2C%209290%20Berlare!5e0!3m2!1snl!2sbe!4v1622884269055!5m2!1snl!2sbe" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3>Algemene Gegevens</h3>
                    <hr />
                    <ul class="list-unstyled">
                        <li><b>VZW Parochiale Werken, afdeling Sint-Martinusfonds</b></li>
                        <li>&nbsp;</li>
                        <li>Baron Tibbautstraat 8, 9290 Berlare</li>
                        <li>Correspondentie: Kerkveld 32a, 9290 Berlare</li>
                        <li>Lokaal: Dorp 33 (naast parochiezaal), 9290 Berlare</li>
                        <li>Bank: BE75 7370 2162 2151</li>
                        <li>Telefoon: +32 (0) 475 96 89 82</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import TheBannerSmall from '../components/layout/TheBannerSmall'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_ENV_BASE_URL

export default {
    data() {
        return {
            body: '',
            name: '',
            email: '',
            messageSent: false,
            messageError: false,
            error: ''
        }
    },
    components: {
        TheBannerSmall
    },
    methods: {
        createMessage () {
            this.messageSent = false
            this.messageError = false
            if (this.email && this.name && this.body) {
                const message = {
                    body: this.body,
                    name: this.name,
                    email: this.email
                }
                axios.post('/api/v1/send-message', message)
                    .then(response => {
                        this.messageSent = true
                        this.body = ''
                        this.name = ''
                        this.email = ''
                    }).catch(error => {
                        this.messageError = true
                        this.error = error
                    })
            }
        }
    }
}
</script>

<style scoped>
.bold {
    font-weight: bold;
}
</style>
